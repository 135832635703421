import { createAsyncThunk } from '@reduxjs/toolkit';

import { ZirtuePayAnalyticsResponseDto } from '..';
import { api } from '../../../../main/network';
import { parseError } from '../../../../shared/utils/helpers';

import type { AppDispatch, RootState } from '../../../../main/store';

export type ZirtuePayAnalyticsSearchQueryDto = {
  from?: string;
  to?: string;
};

export interface ZirtuePayAnalyticsSearchQuery {
  periodTime: string[];
}

export const getZirtuePayAnalyticsApi = async (from: string, to: string) => {
  const params = new URLSearchParams({ from, to }).toString();
  return api.get<ZirtuePayAnalyticsResponseDto>(`/analytics/zirtue-pay?${params}`);
};

export const getZirtuePayAnalytics = createAsyncThunk<
  ZirtuePayAnalyticsResponseDto,
  ZirtuePayAnalyticsSearchQuery,
  { dispatch: AppDispatch; rejectValue: string; state: RootState }
>('getZirtuePayAnalytics', async (payload, { rejectWithValue }) => {
  try {
    const { periodTime } = payload;
    const { data } = await getZirtuePayAnalyticsApi(periodTime[0], periodTime[1]);
    return data;
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const message = parseError(e);
    return rejectWithValue(message);
  }
});
