import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { addMonths } from 'date-fns';

import { formatDateForLoan, getPageSettings, getTimePeriod } from '../../../shared/utils';

import { getZirtuePayAnalytics } from './actions/getZirtuePayAnalytics';

export interface ZirtuePayAnalyticsResponseDto {
  data: ZirtuePayAnalyticsDataDto;
  deltas: ZirtuePayAnalyticsDeltasDto;
}

export interface ZirtuePayAnalyticsDataDto {
  billPayRevenue: ZirtuePayAnalyticsBillPayRevenue[];
  clicks: number;
  downloads: number;
  registrations: number;
  paymentRequests: number;
  paymentsProcessed: number;
  averageAmount: number;
}

export interface ZirtuePayAnalyticsBillPayRevenue {
  date: string;
  value: number;
}

export interface ZirtuePayAnalyticsDeltasDto {
  clicks: number;
  downloads: number;
  registrations: number;
  paymentRequests: number;
  paymentsProcessed: number;
  averageAmount: number;
}
type LoansSliceState = {
  zirtuePaynalytics?: ZirtuePayAnalyticsResponseDto | undefined;
};

const initialState: LoansSliceState = {
  zirtuePaynalytics: undefined,
};

export const zirtuePaySlice = createSlice({
  name: 'zirtue-pay',
  initialState: initialState as LoansSliceState,
  reducers: {
    setZirtuePaynalytics: (
      state,
      action: PayloadAction<ZirtuePayAnalyticsResponseDto | undefined>,
    ) => {
      state.zirtuePaynalytics = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getZirtuePayAnalytics.fulfilled, (state, action) => {
      state.zirtuePaynalytics = action.payload;
    });
  },
});

export default zirtuePaySlice;
export const { reducer: loansReducer } = zirtuePaySlice;
export const { setZirtuePaynalytics } = zirtuePaySlice.actions;
