import { FormikErrors } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { RootState } from '../../../../main/store';
import { useAppSelector } from '../../../../main/store/hooks';
import { Icon, Input } from '../../../../shared';
import { FundingSourceDTO } from '../../../../shared/models/FundingSource';
import {
  FlowType,
  LoanPresetCreateFlowTypeEnum,
  LoanPresetDto,
  LoanType,
  PartnerDeepLinkTypes,
  PartnersDeeplinksFormType,
} from '../../../../shared/models/platform-admin/loans/LoanPresetDto';

import BankingDetails from './BankingDetails';
import { PartnersDeeplinksDetailsButtons } from './PartnersDeeplinksDetailsButtons';
import PartnersDeeplinksDetailsRadio from './PartnersDeeplinksDetailsRadio';
import { PartnersDeeplinksDetailsSection } from './PartnersDeeplinksDetailsSection';

interface PartnersDeeplinksDetailsProps {
  paymentMethods: FundingSourceDTO[];
  isPaymentMethodsEmpty: boolean;
  setIsPaymentMethodsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
  selectedDeeplinkPreset: LoanPresetDto | null;
  values: PartnersDeeplinksFormType;
  setFieldValue: (
    field: string,
    // eslint-disable-next-line
    value: any,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<PartnersDeeplinksFormType>>;
  setValues: (
    values: React.SetStateAction<PartnersDeeplinksFormType>,
    shouldValidate?: boolean | undefined,
  ) => Promise<void> | Promise<FormikErrors<PartnersDeeplinksFormType>>;
  // eslint-disable-next-line
  handleChange: any
  loanType: string | undefined;
  setLoanType: React.Dispatch<React.SetStateAction<string | undefined>>;
}

export const PartnersDeeplinksDetails: FC<PartnersDeeplinksDetailsProps> = ({
  paymentMethods,
  isPaymentMethodsEmpty,
  setIsPaymentMethodsEmpty,
  selectedDeeplinkPreset,
  values,
  setFieldValue,
  setValues,
  handleChange,
  loanType,
  setLoanType,
}) => {
  const selectedDeeplinkPresetLoading = useAppSelector(
    (state: RootState) => state.platformAdmin.selectedDeeplinkPresetLoading,
  );

  const [isBankAccountWarningVisible, setIsBankAccountWarningVisible] = useState(
    paymentMethods.map((el) => el.allowance !== 'VERIFIED'),
  );

  useEffect(() => {
    setIsBankAccountWarningVisible(paymentMethods.map((el) => el.allowance !== 'VERIFIED'));
  }, [paymentMethods]);

  const handleHideWarning = (index: number) => {
    const updatedWarnings = [...isBankAccountWarningVisible];
    updatedWarnings[index] = false;
    setIsBankAccountWarningVisible(updatedWarnings);
  };

  const handleChangeType = (value: string) => {
    setLoanType(value);

    const loanTypeSettings = {
      [LoanType.DIRECT_BILL_PAY_LOANS]: {
        type: PartnerDeepLinkTypes.DBP,
        fields: {
          feeEnabled: values.directBillPay?.feeEnabled,
          whoIsPaying: values.directBillPay?.whoIsPaying,
          feeAmountPercentage: values.directBillPay?.feeAmountPercentage,
        },
        resetFields: {
          'affiliate.type': PartnerDeepLinkTypes.AFFILIATE,
          'affiliate.feeEnabled': null,
          'affiliate.whoIsPaying': null,
          'affiliate.feeAmountPercentage': 0,
          'affiliate.flowType': null,
          'affiliate.partnerComissionEnabled': null,
          'affiliate.partnerComissionPercentageAmount': 0,
        },
      },
      [LoanType.PERSONAL_LOANS]: {
        type: PartnerDeepLinkTypes.AFFILIATE,
        fields: {
          feeEnabled: values.affiliate?.feeEnabled,
          whoIsPaying: values.affiliate?.whoIsPaying,
          feeAmountPercentage: values.affiliate?.feeAmountPercentage,
          flowType: values.affiliate?.flowType,
          partnerComissionEnabled: values.affiliate?.partnerComissionEnabled,
          partnerComissionPercentageAmount: values.affiliate?.partnerComissionPercentageAmount,
        },
        resetFields: {
          'directBillPay.type': PartnerDeepLinkTypes.DBP,
          'directBillPay.feeEnabled': null,
          'directBillPay.whoIsPaying': null,
          'directBillPay.feeAmountPercentage': 0,
        },
      },
    };

    // @ts-ignore
    const settings = loanTypeSettings[value];

    if (settings) {
      setFieldValue('type', settings.type);
      Object.entries(settings.fields).forEach(([key, val]) => setFieldValue(key, val));
      Object.entries(settings.resetFields).forEach(([key, val]) => setFieldValue(key, val));
    }
  };

  const emptyDetailLink = (
    <div className="partners-deeplinks__details-emptyDetailLink">
      <Icon classes="icon-empty-detail-link" name="box-3d" />
      <div className="partners-deeplinks__details-emptyDetailLink-title">No deeplink</div>
      <div className="partners-deeplinks__details-emptyDetailLink-text">
        Generate and activate your link
      </div>
    </div>
  );

  const emptyDetailLinkBlock = (
    <>
      <div className="partners-deeplinks__details-body-column">{emptyDetailLink}</div>
      <div className="partners-deeplinks__details-body-column">
        <BankingDetails
          paymentMethods={paymentMethods}
          isBankAccountWarningVisible={isBankAccountWarningVisible}
          handleHideWarning={handleHideWarning}
          isPaymentMethodsEmpty={isPaymentMethodsEmpty}
          setIsPaymentMethodsEmpty={setIsPaymentMethodsEmpty}
        />
        {emptyDetailLink}
      </div>
    </>
  );

  const selectedDetailLinkBlock = (
    <>
      <div className="partners-deeplinks__details-body-column">
        <PartnersDeeplinksDetailsSection
          title="Integration type"
          disabled={selectedDeeplinkPresetLoading}
        >
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-subtitle">Partner bank account</div>
            <div className="partners-deeplinks__details-section-row is-vertical no-gap">
              <PartnersDeeplinksDetailsRadio
                id="refund-type"
                items={Object.values(LoanType)}
                value={loanType}
                onChange={(e) => handleChangeType(e.target.value)}
              />
            </div>
          </div>
        </PartnersDeeplinksDetailsSection>
        <PartnersDeeplinksDetailsSection
          title="Personal Loan"
          disabled={
            loanType === undefined ||
            selectedDeeplinkPresetLoading ||
            loanType === LoanType.DIRECT_BILL_PAY_LOANS
          }
        >
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-subtitle">Flow type</div>
            <div className="partners-deeplinks__details-section-row">
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Which loan flow will the user experience?
                </div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: FlowType.LOAN_OFFER,
                        answer: LoanPresetCreateFlowTypeEnum.OFFER,
                      },
                      {
                        label: FlowType.LOAN_REQUEST,
                        answer: LoanPresetCreateFlowTypeEnum.REQUEST,
                      },
                    ]}
                    name="affiliate.flowType"
                    value={values.affiliate?.flowType}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-subtitle">Service fee</div>
            <div className="partners-deeplinks__details-section-row is-vertical">
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Charge service fee?
                </div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: 'Yes',
                        answer: true,
                      },
                      {
                        label: 'No',
                        answer: false,
                      },
                    ]}
                    name="affiliate.feeEnabled"
                    secondName="feeEnabled"
                    value={values.affiliate?.feeEnabled}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Who is paying for the service fee?
                </div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: 'Partner',
                        answer: 'partner',
                      },
                      {
                        label: 'End-user',
                        answer: 'end_user',
                      },
                    ]}
                    name="affiliate.whoIsPaying"
                    secondName="whoIsPaying"
                    value={values.affiliate?.whoIsPaying}
                    setFieldValue={setFieldValue}
                    disabled={
                      !!(!values.affiliate?.feeEnabled && values.affiliate?.feeEnabled !== null)
                    }
                  />
                </div>
              </div>
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Service fee amount (%)
                </div>
                <div className="partners-deeplinks__details-section-answer third-width">
                  <Input
                    classes="no-padding-bottom"
                    type="number"
                    id="affiliate.feeAmountPercentage"
                    value={values.affiliate?.feeAmountPercentage}
                    onChange={handleChange}
                    disabled={
                      !!(!values.affiliate?.feeEnabled && values.affiliate?.feeEnabled !== null)
                    }
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-subtitle">Partner commissions</div>
            <div className="partners-deeplinks__details-section-row is-vertical">
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Commission enabled?
                </div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: 'Yes',
                        answer: true,
                      },
                      {
                        label: 'No',
                        answer: false,
                      },
                    ]}
                    name="affiliate.partnerComissionEnabled"
                    value={values.affiliate?.partnerComissionEnabled}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Partner’s % commission of service fee?
                </div>
                <div className="partners-deeplinks__details-section-answer third-width">
                  <Input
                    classes="no-padding-bottom"
                    type="number"
                    id="affiliate.partnerComissionPercentageAmount"
                    value={values.affiliate?.partnerComissionPercentageAmount}
                    onChange={handleChange}
                    disabled={
                      !!(
                        !values.affiliate?.partnerComissionEnabled &&
                        values.affiliate?.partnerComissionEnabled !== null
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </PartnersDeeplinksDetailsSection>
        <PartnersDeeplinksDetailsSection
          title="Direct Bill Pay Loan"
          disabled={
            loanType === undefined ||
            selectedDeeplinkPresetLoading ||
            loanType === LoanType.PERSONAL_LOANS
          }
        >
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-subtitle">Service fee</div>
            <div className="partners-deeplinks__details-section-row is-vertical">
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Charge service fee?
                </div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: 'Yes',
                        answer: true,
                      },
                      {
                        label: 'No',
                        answer: false,
                      },
                    ]}
                    name="directBillPay.feeEnabled"
                    secondName="feeEnabled"
                    value={values.directBillPay?.feeEnabled}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
              <div className="divider" />
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Who is paying for the service fee?
                </div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: 'Partner',
                        answer: 'partner',
                      },
                      {
                        label: 'End-user',
                        answer: 'end_user',
                      },
                    ]}
                    name="directBillPay.whoIsPaying"
                    secondName="whoIsPaying"
                    value={values.directBillPay?.whoIsPaying}
                    setFieldValue={setFieldValue}
                    disabled={
                      !!(
                        !values.directBillPay?.feeEnabled &&
                        values.directBillPay?.feeEnabled !== null
                      )
                    }
                  />
                </div>
              </div>
              <div className="divider" />
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Service fee amount (%)
                </div>
                <div className="partners-deeplinks__details-section-answer third-width">
                  <Input
                    classes="no-padding-bottom"
                    type="number"
                    id="directBillPay.feeAmountPercentage"
                    value={values.directBillPay?.feeAmountPercentage}
                    onChange={handleChange}
                    disabled={
                      !!(
                        !values.directBillPay?.feeEnabled &&
                        values.directBillPay?.feeEnabled !== null
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </PartnersDeeplinksDetailsSection>
      </div>
      <div className="partners-deeplinks__details-body-column">
        <BankingDetails
          paymentMethods={paymentMethods}
          isBankAccountWarningVisible={isBankAccountWarningVisible}
          handleHideWarning={handleHideWarning}
          isPaymentMethodsEmpty={isPaymentMethodsEmpty}
          setIsPaymentMethodsEmpty={setIsPaymentMethodsEmpty}
        />
        <PartnersDeeplinksDetailsSection
          title="End-user UX"
          disabled={selectedDeeplinkPresetLoading}
        >
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-subtitle">KYC</div>
            <div className="partners-deeplinks__details-section-row is-vertical">
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Has the partner-referred user been KYCd?
                </div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: 'Yes',
                        answer: true,
                      },
                      {
                        label: 'No',
                        answer: false,
                      },
                    ]}
                    name="skipKYC"
                    value={values.skipKYC}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="divider" />
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-row is-vertical">
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">
                  Enable alternative funding partners?
                </div>
              </div>
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question">Fiona</div>
                <div className="partners-deeplinks__details-section-answer">
                  <PartnersDeeplinksDetailsButtons
                    options={[
                      {
                        label: 'Yes',
                        answer: true,
                      },
                      {
                        label: 'No',
                        answer: false,
                      },
                    ]}
                    name="useFiona"
                    value={values.useFiona}
                    setFieldValue={setFieldValue}
                  />
                </div>
              </div>
            </div>
          </div>
        </PartnersDeeplinksDetailsSection>
      </div>
    </>
  );

  useEffect(() => {
    setLoanType(undefined);

    const newValues: Partial<PartnersDeeplinksFormType> = {
      uuid: selectedDeeplinkPreset?.uuid,
      type: selectedDeeplinkPreset?.settings?.type,
      // @ts-ignore
      directBillPay: {
        feeEnabled: null,
        whoIsPaying: null,
        feeAmountPercentage: 0,
      },
      // @ts-ignore
      affiliate: {
        feeEnabled: null,
        whoIsPaying: null,
        feeAmountPercentage: 0,
        flowType: null,
        partnerComissionEnabled: null,
        partnerComissionPercentageAmount: 0,
      },
      skipKYC: selectedDeeplinkPreset?.skipKYC,
      useFiona: selectedDeeplinkPreset?.useFiona,
    };

    if (selectedDeeplinkPreset?.settings?.type === PartnerDeepLinkTypes.DBP) {
      newValues.type = LoanType.DIRECT_BILL_PAY_LOANS;
      // @ts-ignore
      newValues.directBillPay = {
        feeEnabled: selectedDeeplinkPreset.settings.feeEnabled,
        whoIsPaying: selectedDeeplinkPreset.settings.whoIsPaying,
        feeAmountPercentage: selectedDeeplinkPreset.settings.feeAmountPercentage,
      };
    }

    if (selectedDeeplinkPreset?.settings?.type === PartnerDeepLinkTypes.AFFILIATE) {
      newValues.type = LoanType.PERSONAL_LOANS;
      // @ts-ignore
      newValues.affiliate = {
        feeEnabled: selectedDeeplinkPreset.settings.feeEnabled,
        whoIsPaying: selectedDeeplinkPreset.settings.whoIsPaying,
        feeAmountPercentage: selectedDeeplinkPreset.settings.feeAmountPercentage,
        flowType: selectedDeeplinkPreset.settings.flowType,
        partnerComissionEnabled: selectedDeeplinkPreset.settings.partnerComissionEnabled,
        partnerComissionPercentageAmount:
          selectedDeeplinkPreset.settings.partnerComissionPercentageAmount,
      };
    }
    // @ts-ignore
    setValues(newValues);
    setLoanType(newValues.type as string | undefined);
  }, [selectedDeeplinkPreset]);

  useEffect(() => {
    if (values.type === PartnerDeepLinkTypes.DBP) {
      setFieldValue('feeEnabled', values.directBillPay?.feeEnabled);
      setFieldValue('whoIsPaying', values.directBillPay?.whoIsPaying);
      setFieldValue('feeAmountPercentage', values.directBillPay?.feeAmountPercentage);

      if (!values.directBillPay?.feeEnabled) {
        setFieldValue('directBillPay.whoIsPaying', null);
        setFieldValue('directBillPay.feeAmountPercentage', 0);
      }
    }

    if (values.type === PartnerDeepLinkTypes.AFFILIATE) {
      setFieldValue('feeEnabled', values.affiliate?.feeEnabled);
      setFieldValue('whoIsPaying', values.affiliate?.whoIsPaying);
      setFieldValue('feeAmountPercentage', values.affiliate?.feeAmountPercentage);

      setFieldValue('flowType', values.affiliate?.flowType);
      setFieldValue('partnerComissionEnabled', values.affiliate?.partnerComissionEnabled);
      setFieldValue(
        'partnerComissionPercentageAmount',
        values.affiliate?.partnerComissionPercentageAmount,
      );

      if (!values.affiliate?.partnerComissionEnabled) {
        setFieldValue('affiliate.partnerComissionPercentageAmount', 0);
      }

      if (!values.affiliate?.feeEnabled) {
        setFieldValue('affiliate.whoIsPaying', null);
        setFieldValue('affiliate.feeAmountPercentage', 0);
      }
    }

    setTimeout(() => {
      setFieldValue('uuid', selectedDeeplinkPreset?.uuid);
    }, 1000);
  }, [values.affiliate, values.directBillPay, values.useFiona, values.skipKYC]);

  return (
    <div className="partners-deeplinks__details">
      <h2 className="partners-deeplinks__details-title">CAMPAIGN DETAILS</h2>
      <div className="partners-deeplinks__details-body">
        {selectedDeeplinkPreset === null ? emptyDetailLinkBlock : selectedDetailLinkBlock}
      </div>
    </div>
  );
};
