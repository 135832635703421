import React, { useEffect } from 'react';

import { useAppDispatch } from '../../../main/store/hooks';
import { getPaymentMethods } from '../../finances/store/actions';
import AuthorizationSuccessLayout from '../layout/AuthorizationSuccessLayout';

export default function SuccessActivate() {
  // const dispatch = useAppDispatch();

  // useEffect(() => {
  //   dispatch(getPaymentMethods());
  // }, []);

  return (
    <AuthorizationSuccessLayout>
      <h1 className="auth-success__title">You’ve successfully activated your account!</h1>
      <p className="auth-success__text auth-success__text--activate">
        Thank you for taking steps to verify that it’s really you. Now you added an extra layer of
        security to your account.
      </p>
    </AuthorizationSuccessLayout>
  );
}
