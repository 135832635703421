import AnalyticsSection from './AnalyticsSection';
import LineChart from './LineChart';

interface LineChartSectionProps {
  title: string;
  value?: number;
  percent: number;
  width: string;
  hasDollar?: boolean;
}
export default function LineChartSectionGraph({
  title,
  value = 0,
  percent,
  width,
  hasDollar,
}: LineChartSectionProps) {
  return (
    <div
      style={{
        width,
      }}
      className="card card--shadow loans-overview__lineChart-section-zp__graph"
    >
      <h3 className="loans-overview__metric-section__title">{title}</h3>

      <div className="loans-overview__lineChart-section-zp">
        <AnalyticsSection value={value} percent={percent} hasDollar={hasDollar} />
        <LineChart />
      </div>
    </div>
  );
}
