import React, { FC } from 'react';

import { Icon } from '../../../../shared';
import { FundingSourceDTO } from '../../../../shared/models/FundingSource';

import { PartnersDeeplinksDetailsSection } from './PartnersDeeplinksDetailsSection';

interface BankingDetailsProps {
  paymentMethods: FundingSourceDTO[];
  isBankAccountWarningVisible: boolean[];
  handleHideWarning: (index: number) => void;
  isPaymentMethodsEmpty: boolean;
  setIsPaymentMethodsEmpty: React.Dispatch<React.SetStateAction<boolean>>;
}

const BankingDetails: FC<BankingDetailsProps> = ({
  paymentMethods,
  isBankAccountWarningVisible,
  handleHideWarning,
  isPaymentMethodsEmpty,
  setIsPaymentMethodsEmpty,
}) => (
  <PartnersDeeplinksDetailsSection title="Banking details">
    {paymentMethods.length ? (
      paymentMethods.map((el, index) => (
        <>
          <div className="partners-deeplinks__details-section-body-container">
            <div className="partners-deeplinks__details-section-subtitle">Partner bank account</div>
            <div className="partners-deeplinks__details-section-row is-vertical">
              <div className="partners-deeplinks__details-section-qaBlock">
                <div className="partners-deeplinks__details-section-question partners-deeplinks__details-section-question-isBankingDetails">
                  Bank account
                  {el?.allowance === 'VERIFIED' ? <span>Linked</span> : ''}
                </div>
              </div>
              {isBankAccountWarningVisible[index] && el.allowance !== 'VERIFIED' ? (
                <div className="partners-deeplinks__details-section-question-isBankingDetails-isEmpty">
                  <div className="partners-deeplinks__details-section-question-isBankingDetails-isEmpty-container">
                    <Icon name="alert-warning-orange" />
                    Partner has not added a bank account
                  </div>
                  {/* eslint-disable-next-line */}
                  <div
                    className="partners-deeplinks__details-section-question-isBankingDetails-isEmpty-button"
                    onClick={() => handleHideWarning(index)}
                  >
                    <Icon name="close-orange" />
                  </div>
                </div>
              ) : (
                <>
                  <div className="divider" />
                  <div className="partners-deeplinks__details-section-qaBlock">
                    <div className="partners-deeplinks__details-section-question partners-deeplinks__details-section-question-isBankingDetails">
                      ACH account ending in
                      <span>{el?.redactedAccountNumber ? el?.redactedAccountNumber : ''}</span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <>
            {el?.redactedAccountNumber ? (
              <div className="partners-deeplinks__details-section-body-container">
                <div className="partners-deeplinks__details-section-subtitle">Link method</div>
                <div className="partners-deeplinks__details-section-answer">
                  <span>{el?.verificationFlow}</span>
                </div>
              </div>
            ) : (
              ''
            )}
          </>
        </>
      ))
    ) : (
      <>
        <div className="partners-deeplinks__details-section-body-container">
          <div className="partners-deeplinks__details-section-subtitle">Partner bank account</div>
          <div className="partners-deeplinks__details-section-row is-vertical">
            <div className="partners-deeplinks__details-section-qaBlock">
              <div className="partners-deeplinks__details-section-question partners-deeplinks__details-section-question-isBankingDetails">
                Bank account
              </div>
            </div>
            {isPaymentMethodsEmpty ? (
              <div className="partners-deeplinks__details-section-question-isBankingDetails-isEmpty">
                <div className="partners-deeplinks__details-section-question-isBankingDetails-isEmpty-container">
                  <Icon name="alert-warning-orange" />
                  Partner has not added a bank account
                </div>
                {/* eslint-disable-next-line */}
                <div
                  className="partners-deeplinks__details-section-question-isBankingDetails-isEmpty-button"
                  onClick={() => setIsPaymentMethodsEmpty(false)}
                >
                  <Icon name="close-orange" />
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
        <div className="partners-deeplinks__details-section-body-container">
          <div className="partners-deeplinks__details-section-subtitle">Link method</div>
        </div>
      </>
    )}
  </PartnersDeeplinksDetailsSection>
);

export default BankingDetails;
