import { useFlag } from '@unleash/proxy-client-react';
import { Dispatch, SetStateAction } from 'react';

import { FeatureTogglesNames } from '../../../main/env/feature-toggle-names';
import { DateRangeTuple } from '../../../shared';
import Notification from '../../loans/components/OverView/Notification';
import { ZirtuePayAnalyticsResponseDto } from '../store';

import LineChartSection from './Charts/LineChartSection';
import LineChartSectionGraph from './Charts/LineChartSectionGraph';
import MetricChart from './Charts/MetricChart';
import Filters from './Filters';

interface OverviewMetricsProps {
  zirtuePaynalytics: ZirtuePayAnalyticsResponseDto;
  periodTime: DateRangeTuple;
  setPeriodTime: Dispatch<SetStateAction<DateRangeTuple>>;
  isDemoMode: boolean;
}

export default function OverviewMetrics({
  zirtuePaynalytics,
  periodTime,
  setPeriodTime,
  isDemoMode,
}: OverviewMetricsProps) {
  const ZIRTUE_PAY_ANALYTIC_DOWNLOADS_CLICKS_GRAPHS = useFlag(
    FeatureTogglesNames.ZIRTUE_PAY_ANALYTIC_DOWNLOADS_CLICKS_GRAPHS,
  );
  const billPayRevenueData = zirtuePaynalytics.data.billPayRevenue;
  const totalBillPayRevenue = billPayRevenueData.reduce((sum, item) => sum + item.value, 0);
  const normalizedBillPayRevenueData =
    billPayRevenueData.length === 1
      ? [
          {
            date: new Date(new Date(billPayRevenueData[0].date).getTime() - 86400000).toISOString(),
            value: 0,
          },
          ...billPayRevenueData,
          {
            date: new Date(new Date(billPayRevenueData[0].date).getTime() + 86400000).toISOString(),
            value: 0,
          },
        ]
      : billPayRevenueData;

  const chartWidth = ZIRTUE_PAY_ANALYTIC_DOWNLOADS_CLICKS_GRAPHS
    ? 'calc(33.33333% - 13px)'
    : 'calc(50% - 12px)';

  return (
    <div style={{ marginBottom: 24 }} className="card card--zp-shadow">
      {isDemoMode && <Notification />}

      <Filters setPeriodTime={setPeriodTime} disabled={isDemoMode} isDemoMode={isDemoMode} />
      <section
        className="loans-overview__metric-section-zp"
        style={{ flexDirection: 'column', opacity: isDemoMode ? '0.5' : '1' }}
      >
        <div style={{ width: '100%', display: 'flex', gap: 24 }}>
          {ZIRTUE_PAY_ANALYTIC_DOWNLOADS_CLICKS_GRAPHS && (
            <LineChartSection
              title="Zirtue Pay Clicks"
              iconName="blue-cursor-click"
              value={isDemoMode ? 316 : zirtuePaynalytics.data.clicks}
              percent={isDemoMode ? 25 : zirtuePaynalytics.deltas.clicks}
              width={chartWidth}
            />
          )}
          {ZIRTUE_PAY_ANALYTIC_DOWNLOADS_CLICKS_GRAPHS && (
            <LineChartSection
              title="Zirtue Downloads"
              iconName="blue-download"
              value={isDemoMode ? 316 : zirtuePaynalytics.data.downloads}
              percent={isDemoMode ? 25 : zirtuePaynalytics.deltas.downloads}
              width={chartWidth}
            />
          )}
          {ZIRTUE_PAY_ANALYTIC_DOWNLOADS_CLICKS_GRAPHS ? (
            <LineChartSection
              title="Zirtue Registrations"
              iconName="blue-registration"
              value={isDemoMode ? 124 : zirtuePaynalytics.data.registrations}
              percent={isDemoMode ? 25 : zirtuePaynalytics.deltas.registrations}
              width={chartWidth}
            />
          ) : (
            <>
              <LineChartSection
                title="Zirtue Registrations"
                iconName="blue-registration"
                value={isDemoMode ? 124 : zirtuePaynalytics.data.registrations}
                percent={isDemoMode ? 25 : zirtuePaynalytics.deltas.registrations}
                width={chartWidth}
              />
              <LineChartSectionGraph
                title="Payment Requests"
                value={isDemoMode ? 400 : zirtuePaynalytics.data.paymentRequests}
                percent={isDemoMode ? 12 : zirtuePaynalytics.deltas.paymentRequests}
                width={chartWidth}
              />
            </>
          )}
        </div>

        <div style={{ width: '100%', display: 'flex', gap: 24 }}>
          {ZIRTUE_PAY_ANALYTIC_DOWNLOADS_CLICKS_GRAPHS ? (
            <LineChartSectionGraph
              title="Payment Requests"
              value={isDemoMode ? 400 : zirtuePaynalytics.data.paymentRequests}
              percent={isDemoMode ? 12 : zirtuePaynalytics.deltas.paymentRequests}
              width={chartWidth}
            />
          ) : (
            ''
          )}
          <LineChartSectionGraph
            title="View Payments Processed"
            value={isDemoMode ? 124 : zirtuePaynalytics.data.paymentsProcessed}
            percent={isDemoMode ? 12 : zirtuePaynalytics.deltas.paymentsProcessed}
            width={chartWidth}
          />
          <LineChartSectionGraph
            title="Average Payment Amount"
            value={isDemoMode ? 121.32 : zirtuePaynalytics.data.averageAmount}
            percent={isDemoMode ? 12 : zirtuePaynalytics.deltas.averageAmount}
            width={chartWidth}
            hasDollar
          />
        </div>

        {/* <div style={{ width: '100%' }}>
          <MetricChart
            title="Bill Pay Revenue"
            value={totalBillPayRevenue}
            data={normalizedBillPayRevenueData}
          />
        </div> */}
      </section>
    </div>
  );
}
