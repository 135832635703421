import React, { ReactElement } from 'react';
import Popup from 'reactjs-popup';
// eslint-disable-next-line import/no-unresolved
import { PopupPosition } from 'reactjs-popup/dist/types';

type Props = {
  children: ReactElement;
  title?: string;
  text: string;
  arrowPosition?: 'nearLeftCorner' | 'center' | 'filter';
  position?: PopupPosition;
  offsetY?: number;
  onHover?: () => void;
};

export default function Tooltip({
  children,
  title,
  text,
  arrowPosition,
  position,
  offsetY = 0,
  onHover,
}: Props) {
  const offsetX =
    window.innerHeight >= document.body.offsetHeight &&
    window.innerWidth > 1120 &&
    navigator.userAgent.indexOf('Windows') !== -1
      ? 8
      : 0;

  return (
    <Popup
      trigger={children}
      on={['hover']}
      position={position || 'bottom center'}
      className={arrowPosition}
      offsetX={offsetX}
      offsetY={offsetY}
      onOpen={onHover}
    >
      <span className="tooltip">
        {title ? <div className="tooltip__title">{title}</div> : ''}
        <div className="tooltip__text">{text}</div>
      </span>
    </Popup>
  );
}
