import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { usePlaidLink } from 'react-plaid-link';
import { shallowEqual } from 'react-redux';

import { FlowTypes, setCurrentStepSetup } from '../../../../features/auth/store';
import { completeOnboarding } from '../../../../features/auth/store/actions/completeOnboarding';
import { selectChecklistFlow } from '../../../../features/auth/store/actions/selectChecklistFlow';
import { api } from '../../../../main/network';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { defaultRealm } from '../../../constants/DefaultRealm';
import { Amplitude, AmplitudeEvents } from '../../../services/amplitude';
import { AlertType, addAlert } from '../../../store/modals';
import { PlaidRequestBody } from '../PlaidButton';

type LinkTokenDta = {
  linkToken: string;
  expiratin: string;
};

type usePlaidButtonProps = {
  requestBody: PlaidRequestBody;
  next: () => void;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  companyAddres?: string;
};

const usePlaidButton = ({
  requestBody,
  loading,
  next,
  setLoading,
  companyAddres,
}: usePlaidButtonProps) => {
  const businessName = useAppSelector((state) => state.auth.partner?.details.name, shallowEqual);
  const { selectedFlow } = useAppSelector((state) => state.auth);

  const [linkToken, setLinkToken] = useState('');
  const dispatch = useAppDispatch();

  const updatePartner = async (data: { postalAddress: string }) => {
    try {
      await api.put('/partners', data);
      return true;
    } catch (error) {
      dispatch(addAlert({ text: 'Error while creating account.', type: AlertType.error }));
    } finally {
      setLoading(false);
    }
    return false;
  };

  const handleError = (error: unknown) => {
    const errorMessage = (error as string) || 'An error occurred.';
    dispatch(addAlert({ text: errorMessage, type: AlertType.error }));
  };

  async function determineFlow(flowType: keyof typeof FlowTypes) {
    try {
      setLoading(true);
      const completeOnboardingResponse = await dispatch(completeOnboarding({ flowType }));
      setLoading(false);

      if (completeOnboarding.fulfilled.match(completeOnboardingResponse)) {
        if (companyAddres) await updatePartner({ postalAddress: companyAddres });
        const selectChecklistFlowResponse = await dispatch(
          selectChecklistFlow({ flowType: 'billpay' }),
        );

        if (selectChecklistFlow.fulfilled.match(selectChecklistFlowResponse)) {
          dispatch(setCurrentStepSetup(4));
          next();
        } else {
          handleError(selectChecklistFlowResponse.payload);
        }
      } else {
        handleError(completeOnboardingResponse.payload);
      }
    } catch (error) {
      setLoading(false);
      dispatch(addAlert({ text: 'An unexpected error occurred.', type: AlertType.error }));
    }
  }

  const exchangePublickToken = async (data: PlaidRequestBody) => {
    const flow =
      selectedFlow === FlowTypes.AFFILIATE_PARTNER
        ? 'DF'
        : selectedFlow === FlowTypes.BILL_PAY
        ? 'ZP'
        : selectedFlow === FlowTypes.LOAN_CREATOR
        ? 'LC'
        : selectedFlow === FlowTypes.BNPL
        ? 'BNPL'
        : '';

    setLoading(true);
    try {
      const encodedURI = encodeURIComponent(`${defaultRealm}`);
      const response = await api.put(`/realms/${encodedURI}/accounts`, data);
      if (response.data) {
        Amplitude.logEvent(AmplitudeEvents.accountAdded, {
          verificationType: 'plaid',
          flow,
        });
        determineFlow(selectedFlow);
      }
    } catch (error) {
      dispatch(
        addAlert({
          text: 'There is an error occurring when adding bank account',
          type: AlertType.error,
        }),
      );
    }
    setLoading(false);
  };

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess: (publicToken, metaData) => {
      const requestData: PlaidRequestBody = {
        ...requestBody,
        publicToken,
        accounts: metaData.accounts,
        businessName,
      };
      exchangePublickToken(requestData);
    },
  });

  const getLinkToken = async () => {
    try {
      const response = await api.post<LinkTokenDta>('/plaid/link-token');
      setLinkToken(response.data.linkToken);
    } catch (error) {
      dispatch(
        addAlert({
          text: 'There is an error occurring when adding banc account',
          type: AlertType.error,
        }),
      );
    }
  };

  useEffect(() => {
    getLinkToken();
  }, []);

  return {
    operations: {
      open,
    },
    properties: {},
  };
};

export default usePlaidButton;
