import { SetStateAction, Dispatch } from 'react';
import { useHistory } from 'react-router-dom';

import { DateRangeFilter, DateRangeTuple } from '../../../shared';
import Button from '../../../shared/components/Button';

interface FiltersProps {
  setPeriodTime: Dispatch<SetStateAction<DateRangeTuple>>;
  disabled?: boolean;
  isDemoMode: boolean;
}

export default function Filters({ disabled = true, setPeriodTime, isDemoMode }: FiltersProps) {
  const history = useHistory();

  const handleDateFilter = (value: DateRangeTuple) => {
    setPeriodTime(value);
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        gap: 15,
        marginBottom: 32,
      }}
    >
      {!isDemoMode && (
        <Button
          className="button button--outline loans-overview__metric-section__filterButton"
          icon="arrow-blue-bold"
          onClick={() => {
            history.push('/payments');
          }}
        >
          View payments
        </Button>
      )}

      <div style={{ marginBottom: 0 }} className="table-filter__col">
        <DateRangeFilter
          classes="table-filter__date-filter"
          optionsClasses="datepicker__options__zirtue-pay"
          onChangePeriod={(value) => handleDateFilter(value)}
          placeholder="Select date"
          disabled={disabled}
          showDays={isDemoMode ? 30 : undefined}
        />
      </div>
    </div>
  );
}
