import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../main/store/hooks';
import { DateRangeTuple } from '../../../shared';
import DashboardLayout from '../../dashboard/layout/DashboardLayout';
import RecentNews from '../../loans/components/OverView/RecentNews';
import OverviewMetrics from '../components/OverviewMetrics';
import {
  getZirtuePayAnalytics,
  getZirtuePayAnalyticsApi,
} from '../store/actions/getZirtuePayAnalytics';

export default function ZirtuePayOverview() {
  const dispatch = useAppDispatch();
  const { zirtuePaynalytics } = useAppSelector((state) => state.zirtuePay);
  const { partner } = useAppSelector((state) => state.auth);

  const updatedPeriodTime: DateRangeTuple = partner?.createdAt
    ? [new Date(partner.createdAt).toISOString(), new Date().toISOString()]
    : ['', new Date().toISOString()];

  const [periodTime, setPeriodTime] = useState<DateRangeTuple>(updatedPeriodTime);
  const [isDemoMode, setIsDemoMode] = useState<boolean>(false);
  const [isAnalyticsLoading, setIsAnalyticsLoading] = useState<boolean>(false);

  const getAnalytics = () => {
    dispatch(
      getZirtuePayAnalytics({
        periodTime,
      }),
    );
  };

  useEffect(() => {
    const fetchAnalytics = async () => {
      setIsAnalyticsLoading(true);
      try {
        const { data } = await getZirtuePayAnalyticsApi(periodTime[0], periodTime[1]);

        if (
          data.data.clicks !== 0 ||
          data.data.registrations !== 0 ||
          data.data.paymentRequests !== 0 ||
          data.data.paymentsProcessed !== 0
        ) {
          setIsDemoMode(false);
        } else {
          setIsDemoMode(true);
        }
      } catch (error) {
        console.error('error', error);
      } finally {
        setIsAnalyticsLoading(false);
      }
    };

    fetchAnalytics();
  }, []);

  useEffect(() => {
    getAnalytics();
  }, [periodTime]);

  const renderContent = () => {
    if (isAnalyticsLoading) {
      return (
        <div
          style={{
            marginBottom: 24,
            height: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="card card--zp-shadow"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div className="loader" />
          </div>
        </div>
      );
    }

    if (!zirtuePaynalytics) {
      return (
        <div
          style={{
            marginBottom: 24,
            height: 600,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          className="card card--zp-shadow"
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          />
        </div>
      );
    }

    return (
      <OverviewMetrics
        zirtuePaynalytics={zirtuePaynalytics}
        periodTime={periodTime}
        setPeriodTime={setPeriodTime}
        isDemoMode={isDemoMode}
      />
    );
  };

  return (
    <DashboardLayout dashboardContentClassname="dashboard__content__zp-overview">
      {partner && (
        <>
          <div className="loans-overview__title-wrapper">
            <h2 className="main-title">Zirtue Pay Overview</h2>
          </div>

          {renderContent()}

          <RecentNews />
        </>
      )}
    </DashboardLayout>
  );
}
