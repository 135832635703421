import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

import { useAppSelector } from '../../../main/store/hooks';
import { FlowTypes } from '../store';

interface IProps {
  children: ReactNode;
}

const AuthorizationSuccessLayout: React.FC<IProps> = ({ children }: IProps) => {
  const { partner } = useAppSelector((state) => state.auth);

  return (
    <section className="auth-success">
      <div className="auth-success__box">
        <div className="auth-success__illustration">
          <img
            src="images/auth/success/blob.svg"
            alt=""
            className="auth-success__illustration-img auth-success__illustration-img--lv1"
          />
          <picture>
            <source
              srcSet="images/auth/success/hand@1x.webp 1x, images/auth/success/hand@2x.webp 2x"
              type="image/webp"
            />
            <img
              src="images/auth/success/hand@1x.png"
              srcSet="images/auth/success/hand@2x.png 2x"
              alt=""
              className="auth-success__illustration-img auth-success__illustration-img--lv2"
            />
          </picture>
          <img
            src="images/auth/success/hand-part.svg"
            alt=""
            className="auth-success__illustration-img auth-success__illustration-img--lv3"
          />
          <img
            src="images/auth/success/lock.svg"
            alt=""
            className="auth-success__illustration-img auth-success__illustration-img--lv4"
          />
        </div>
        {children}
        <Link
          to={
            partner?.details.flowType === FlowTypes.BILL_PAY
              ? '/zirtue-pay-overview'
              : '/company-profile'
          }
          className="button button--primary-blue button--lg button--block"
        >
          Got it
        </Link>
      </div>
    </section>
  );
};

export default AuthorizationSuccessLayout;
