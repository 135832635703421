import { useFormik } from 'formik';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { api } from '../../../../main/network';
import { RootState } from '../../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import Button from '../../../../shared/components/Button';
import useMultistepForm from '../../../../shared/hooks/useMultistepForm';
import { Address } from '../../../../shared/models/AddAchNodeDto';
import { Amplitude, AmplitudeEvents } from '../../../../shared/services/amplitude';
import { AlertType, addAlert, setModalSuccessOpen } from '../../../../shared/store/modals';
import { FlowTypes, setCurrentStepSetup } from '../../../auth/store';
import { completeOnboarding } from '../../../auth/store/actions/completeOnboarding';
import { selectChecklistFlow } from '../../../auth/store/actions/selectChecklistFlow';
import { setShowBanner } from '../../../dashboard/store/dashboardSlice';
import { firstSchema, secondSchema } from '../FormSchema';
import { Connect, Register } from '../FormSteps';
import LastStep from '../SelectionFlow/LastStep';

export type FormData = {
  ein: string;
  address?: Address;
  routingNumber: string;
  accountNumber: string;
  businessName: string;
};

export type OnboardingBusinessData = {
  businessName: string;
  ein: string;
  principalAddress: Address;
  routingNumber: string;
  accountNumber: string;
};

const INITIAL_DATA: FormData = {
  ein: '',
  address: undefined,
  businessName: '',
  routingNumber: '',
  accountNumber: '',
};

export enum SuccessfulStatus {
  successTitle = 'Bank account pending verification',
  successText = 'We will verify this bank account by sending micro-deposits. It may take 1 to 2 business days for these deposits to show up in the connected account. We will remind you to verify them.',
}

export default function MultiStepForm() {
  const addressRef = useRef<HTMLInputElement>(null);
  const dispatch = useAppDispatch();
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const businessName = useSelector((state: RootState) => state.auth.partner?.details.name);
  const validationSchemas = [firstSchema, secondSchema];

  const [companyAddres, setCompanyAddres] = useState('');
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: INITIAL_DATA,
    validationSchema: validationSchemas[currentStepIndex],
    validateOnBlur: true,
    validateOnChange: true,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    onSubmit: onSubmitHandler,
  });

  const {
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    handleSubmit,
    isValid,
    validateForm,
    validateField,
    setFieldValue,
    setFieldTouched,
    resetForm,
    setErrors,
    setTouched,
  } = formik;

  const propsData = {
    values,
    handleChange,
    handleBlur,
    validateField,
    touched,
    errors,
    setFieldValue,
    setFieldTouched,
    resetForm,
  };

  const goToLastStep = () => {
    setCurrentStepIndex(2);
  };

  const { currentStepSetup, selectedFlow } = useAppSelector((state) => state.auth);

  const { step, isLastStep, back, next, isRequestStep, isFirstStep } = useMultistepForm({
    steps: [
      <Register addressRef={addressRef} {...propsData} setCompanyAddres={setCompanyAddres} />,
      <Connect
        {...propsData}
        loading={loading}
        next={goToLastStep}
        setLoading={setLoading}
        companyAddres={companyAddres}
      />,
      <LastStep />,
    ],
    currentStepIndex,
    setCurrentStepIndex,
  });

  const updatePartner = async (data: { postalAddress: string }) => {
    try {
      await api.put('/partners', data);
      return true;
    } catch (error) {
      dispatch(addAlert({ text: 'Error while creating account.', type: AlertType.error }));
    } finally {
      setLoading(false);
    }
    return false;
  };

  const handleError = (error: unknown) => {
    const errorMessage = (error as string) || 'An error occurred.';
    dispatch(addAlert({ text: errorMessage, type: AlertType.error }));
  };

  async function determineFlow(flowType: keyof typeof FlowTypes, data?: OnboardingBusinessData) {
    try {
      setLoading(true);
      const completeOnboardingResponse = await dispatch(completeOnboarding({ flowType, data }));
      setLoading(false);

      if (completeOnboarding.fulfilled.match(completeOnboardingResponse)) {
        await updatePartner({ postalAddress: companyAddres });
        const selectChecklistFlowResponse = await dispatch(
          selectChecklistFlow({ flowType: 'billpay' }),
        );

        if (selectChecklistFlow.fulfilled.match(selectChecklistFlowResponse)) {
          dispatch(setCurrentStepSetup(4));
          dispatch(
            setModalSuccessOpen({
              opened: true,
              title: SuccessfulStatus.successTitle,
              text: SuccessfulStatus.successText,
            }),
          );
          next();
        } else {
          handleError(selectChecklistFlowResponse.payload);
        }
      } else {
        handleError(completeOnboardingResponse.payload);
      }
    } catch (error) {
      setLoading(false);
      dispatch(addAlert({ text: 'An unexpected error occurred.', type: AlertType.error }));
    }
  }

  async function onSubmitHandler(value: FormData) {
    if (isValid && !isRequestStep) {
      next();
    }

    if (isLastStep) {
      dispatch(setShowBanner(true));
    }

    if (isRequestStep) {
      const data: OnboardingBusinessData = {
        businessName: value.businessName,
        ein: values.ein.replace(/\D/g, ''),
        principalAddress: values.address!,
        routingNumber: values.routingNumber,
        accountNumber: values.accountNumber,
      };
      determineFlow(selectedFlow, data);
    }
  }

  function handleBack() {
    if (isFirstStep) {
      validateForm();
      dispatch(setCurrentStepSetup(1));
    }

    if (currentStepSetup === 2) {
      Amplitude.logEvent(AmplitudeEvents.previousTapped, {
        screen: 'businessInfo',
      });
    }

    if (currentStepSetup === 3) {
      validateForm();
      dispatch(setCurrentStepSetup(2));
      Amplitude.logEvent(AmplitudeEvents.previousTapped, {
        screen: 'bank',
      });
    }

    back();
  }

  const handleNextClick = () => {
    const flow =
      selectedFlow === FlowTypes.AFFILIATE_PARTNER
        ? 'DF'
        : selectedFlow === FlowTypes.BILL_PAY
        ? 'ZP'
        : selectedFlow === FlowTypes.LOAN_CREATOR
        ? 'LC'
        : selectedFlow === FlowTypes.BNPL
        ? 'BNPL'
        : '';
    const formattedAddress = `${values?.address?.line1}, ${values?.address?.city}, ${values?.address?.state}, ${values?.address?.zip}, ${values?.address?.country}`;

    const handleFirstStep = () => {
      Amplitude.logEvent(AmplitudeEvents.ppBusinessInfoSubmitted, {
        ein: values.ein,
        name: values.businessName,
        address: formattedAddress,
        flow,
      });
      dispatch(setCurrentStepSetup(3));
    };

    const handleBankStep = () => {
      Amplitude.logEvent(AmplitudeEvents.accountAdded, {
        verificationType: 'microdeposits',
        routingNumber: values.routingNumber.trim().split(' ').join(''),
        accountNumber: values.accountNumber.trim().split(' ').join(''),
        flow,
      });
      dispatch(setCurrentStepSetup(3));
    };

    if (isFirstStep && values.ein && values.address?.country && isValid) {
      handleFirstStep();
    } else if (currentStepIndex === 1 && values.routingNumber && values.accountNumber && isValid) {
      handleBankStep();
    } else if (isLastStep) {
      dispatch(setCurrentStepSetup(4));
    }
  };

  const buttonDisabled = () => {
    if (isFirstStep && values.ein && values.address?.country && isValid) return false;
    if (currentStepIndex === 1 && values.routingNumber && values.accountNumber && isValid)
      return false;
    return true;
  };

  useEffect(() => {
    setFieldValue('businessName', businessName);
  }, [businessName, setFieldValue]);

  useEffect(() => {
    setTimeout(() => {
      setFieldTouched('businessName');
      setErrors({});
      setTouched({});
    }, 0);
  }, [currentStepIndex, setErrors, setTouched, setFieldTouched]);

  return (
    <>
      <div
        style={{ position: 'relative' }}
        className="init-setup__multiStepForm multiStepForm_container"
      >
        <form onSubmit={handleSubmit} className="multiStepForm__form">
          <div className="multiStepForm__step">{step}</div>
          {!isLastStep && (
            <div className="init-setup__footer">
              {currentStepSetup > 1 && (
                <button
                  disabled={loading}
                  type="button"
                  className="init-setup__button--prev"
                  onClick={handleBack}
                >
                  Previous
                </button>
              )}
              <Button
                disabled={loading || buttonDisabled()}
                type="submit"
                className="button--primary-blue init-setup__button"
                onClick={handleNextClick}
              >
                Next
              </Button>
            </div>
          )}
        </form>
        {loading && (
          <div
            style={{
              position: 'absolute',
              top: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
            }}
          >
            <div style={{ position: 'absolute', top: '50%', left: '50%' }} className="loader" />
          </div>
        )}
      </div>
    </>
  );
}
