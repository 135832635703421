import React, { ReactElement } from 'react';
// eslint-disable-next-line import/no-unresolved
import { PopupPosition } from 'reactjs-popup/dist/types';

import Icon from './Icon';

import { Tooltip } from './index';

type Props = {
  children: ReactElement | string;
  forId?: string;
  infoTitle?: string;
  infoText?: string;
  classes?: string;
  arrowPosition?: 'nearLeftCorner' | 'center';
  position?: PopupPosition;
  handleInfoIconInteraction?: () => void;
};

export default function Label({
  children,
  forId,
  infoTitle,
  infoText,
  classes,
  arrowPosition = 'nearLeftCorner',
  position,
  handleInfoIconInteraction,
}: Props) {
  return (
    <label htmlFor={forId} className={`label ${classes || ''}`}>
      <span className="label__children">{children}</span>
      {infoText && (
        <Tooltip
          title={infoTitle}
          text={infoText}
          arrowPosition={arrowPosition}
          position={position}
          onHover={handleInfoIconInteraction}
        >
          <span className="label__info">
            <Icon name="info" classes="label__info-icon" />
          </span>
        </Tooltip>
      )}
    </label>
  );
}
