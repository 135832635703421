import classNames from 'classnames';
import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';

import { RootState } from '../../../../main/store';
import { useAppDispatch, useAppSelector } from '../../../../main/store/hooks';
import { Icon, Input, Select, Tooltip } from '../../../../shared';
import Button from '../../../../shared/components/Button';
import {
  DeeplinkAlert,
  DeeplinkStatus,
  LoanPresetStateEnum,
  PartnerDeepLinkTypes,
  PartnersDeeplinksFormType,
} from '../../../../shared/models/platform-admin/loans/LoanPresetDto';
import { AlertType, addAlert } from '../../../../shared/store/modals';
import { deeplinkDetailsSchema as validationSchema } from '../../../account/schemas';
import {
  setSelectedDeeplinkPreset,
  setSelectedDeeplinkPresetLoading,
  setSelectedDeeplinkPresets,
} from '../../store';
import { adminLoanPresetActivate } from '../../store/actions/adminLoanPresetActivate';
import { adminLoanPresetById } from '../../store/actions/adminLoanPresetById';
import { adminLoanPresetCreate } from '../../store/actions/adminLoanPresetCreate';
import { adminLoanPresetUpdate } from '../../store/actions/adminLoanPresetUpdate';
import { selectSelectedPartner } from '../../store/selectors/partners';

import { PartnersDeeplinksDetails } from './PartnersDeeplinksDetails';

export const PartnersDeeplinks: FC = () => {
  const [selectedDeeplink, setSelectedDeeplink] = useState<null | string>('No deeplink');
  const dispatch = useAppDispatch();
  const [deepLinkStatusText, setDeepLinkStatusText] = useState(DeeplinkStatus.NO_GENERATED);
  const [loanType, setLoanType] = useState<string | undefined>();
  const [isPaymentMethodsEmpty, setIsPaymentMethodsEmpty] = useState(true);

  const selectedDeeplinkPresets = useAppSelector(
    (state: RootState) => state.platformAdmin.selectedDeeplinkPresets,
  );

  const selectedDeeplinkPreset = useAppSelector(
    (state: RootState) => state.platformAdmin.selectedDeeplinkPreset,
  );

  const selectedDeeplinkPresetLoading = useAppSelector(
    (state: RootState) => state.platformAdmin.selectedDeeplinkPresetLoading,
  );

  const paymentMethods = useAppSelector(
    (state: RootState) => state.platformAdmin.users.selectedUserPaymentMethods,
  );

  const selectedPartner = useAppSelector(selectSelectedPartner);

  const [deepLinkName, setDeepLinkName] = useState<string>(
    selectedDeeplinkPreset?.displayName ? selectedDeeplinkPreset.displayName : '',
  );

  const [isEditingDeeplinkName, setIsEditingDeeplinkName] = useState(false);

  const { handleChange, values, handleSubmit, setFieldValue, setValues, isValid } =
    useFormik<PartnersDeeplinksFormType>({
      initialValues: {
        type: selectedDeeplinkPreset?.settings?.type ? selectedDeeplinkPreset?.settings?.type : '',
        uuid: selectedDeeplinkPreset?.uuid ? selectedDeeplinkPreset?.uuid : '',
        feeEnabled: selectedDeeplinkPreset?.settings?.feeEnabled
          ? selectedDeeplinkPreset?.settings?.feeEnabled
          : null,
        whoIsPaying: selectedDeeplinkPreset?.settings?.whoIsPaying
          ? selectedDeeplinkPreset?.settings?.whoIsPaying
          : null,
        feeAmountPercentage: selectedDeeplinkPreset?.settings?.feeAmountPercentage
          ? selectedDeeplinkPreset?.settings?.feeAmountPercentage
          : 0,
        flowType: selectedDeeplinkPreset?.settings?.flowType
          ? selectedDeeplinkPreset?.settings?.flowType
          : '',
        partnerComissionEnabled: selectedDeeplinkPreset?.settings?.partnerComissionEnabled
          ? selectedDeeplinkPreset?.settings?.partnerComissionEnabled
          : null,
        partnerComissionPercentageAmount: selectedDeeplinkPreset?.settings
          ?.partnerComissionPercentageAmount
          ? selectedDeeplinkPreset?.settings?.partnerComissionPercentageAmount
          : 0,
        directBillPay: {
          type: PartnerDeepLinkTypes.DBP,
          feeEnabled: selectedDeeplinkPreset?.settings?.feeEnabled || null,
          whoIsPaying: selectedDeeplinkPreset?.settings?.whoIsPaying || null,
          feeAmountPercentage: selectedDeeplinkPreset?.settings?.feeAmountPercentage || 0,
        },
        affiliate: {
          type: PartnerDeepLinkTypes.AFFILIATE,
          feeEnabled: selectedDeeplinkPreset?.settings?.feeEnabled || null,
          whoIsPaying: selectedDeeplinkPreset?.settings?.whoIsPaying || null,
          feeAmountPercentage: selectedDeeplinkPreset?.settings?.feeAmountPercentage || 0,
          flowType: selectedDeeplinkPreset?.settings?.flowType || null,
          partnerComissionEnabled:
            selectedDeeplinkPreset?.settings?.partnerComissionEnabled || null,
          partnerComissionPercentageAmount:
            selectedDeeplinkPreset?.settings?.partnerComissionPercentageAmount || 0,
        },
        useFiona: selectedDeeplinkPreset?.useFiona ? selectedDeeplinkPreset?.useFiona : null,
        skipKYC: selectedDeeplinkPreset?.skipKYC ? selectedDeeplinkPreset?.skipKYC : null,
        displayName: selectedDeeplinkPreset?.displayName,
      },
      onSubmit: async () => {
        const { useFiona, skipKYC, type, feeEnabled, whoIsPaying, feeAmountPercentage, affiliate } =
          values;

        const data = {
          uuid: selectedDeeplinkPreset?.uuid,
          displayName: deepLinkName,
          type,
          feeEnabled,
          whoIsPaying,
          feeAmountPercentage,
          flowType: affiliate?.flowType ? affiliate?.flowType : undefined,
          partnerComissionEnabled: affiliate?.partnerComissionEnabled
            ? affiliate?.partnerComissionEnabled
            : undefined,
          partnerComissionPercentageAmount: affiliate?.partnerComissionPercentageAmount
            ? affiliate?.partnerComissionPercentageAmount
            : undefined,
          useFiona,
          skipKYC,
        };
        dispatch(setSelectedDeeplinkPresetLoading(true));
        try {
          if (selectedDeeplinkPreset?.state === LoanPresetStateEnum.ACTIVE) {
            dispatch(
              addAlert({
                text: 'This update may affect the user experience negatively. Please consult the Product team before updating.',
                type: AlertType.info,
              }),
            );
          }
          // @ts-ignore
          await dispatch(adminLoanPresetUpdate(data)).unwrap();

          await new Promise((resolve) => setTimeout(resolve, 3000));

          // @ts-ignore
          await dispatch(adminLoanPresetById(selectedDeeplinkPreset?.uuid));
          dispatch(
            addAlert({
              text: DeeplinkAlert.UPDATE,
              type: AlertType.success,
            }),
          );
          if (!paymentMethods.length) {
            setIsPaymentMethodsEmpty(true);
          }
        } catch (err) {
          console.log(err);
        } finally {
          dispatch(setSelectedDeeplinkPresetLoading(false));
        }
      },
      validationSchema,
      validateOnChange: true,
    });

  useEffect(() => {
    if (selectedPartner?.uuid) {
      setSelectedDeeplink('No deeplink');
      setDeepLinkName('');
      dispatch(setSelectedDeeplinkPreset(null));
      setDeepLinkStatusText(DeeplinkStatus.NO_GENERATED);
    }
  }, [selectedPartner]);

  useEffect(() => {
    if (!isEditingDeeplinkName) {
      setDeepLinkName(selectedDeeplinkPreset?.displayName || '');
    }
  }, [selectedDeeplinkPreset, isEditingDeeplinkName]);

  const handleChangeSelectDeeplink = async (deeplink: string) => {
    dispatch(setSelectedDeeplinkPresetLoading(true));

    try {
      // @ts-ignore
      await dispatch(adminLoanPresetById(deeplink));
      setSelectedDeeplink(deeplink);

      // @ts-ignore
      const foundObject = selectedDeeplinkPresets.find((link) => link.dataId === deeplink);

      if (foundObject) {
        if (foundObject.state !== LoanPresetStateEnum.ACTIVE) {
          setDeepLinkStatusText(DeeplinkStatus.GENERATED);
        } else if (foundObject.state === LoanPresetStateEnum.ACTIVE) {
          setDeepLinkStatusText(DeeplinkStatus.ACTIVATED);
        }
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setSelectedDeeplinkPresetLoading(false));
    }
  };

  const TIMEOUT_DURATION = 3000;

  // @ts-ignore
  const createDeeplink = async (deeplinkBody) => {
    // @ts-ignore
    const result = await dispatch(adminLoanPresetCreate(deeplinkBody)).unwrap();
    return result;
  };

  const activateDeeplink = async () => {
    // @ts-ignore
    await dispatch(adminLoanPresetActivate(selectedDeeplink)).unwrap();
  };

  // @ts-ignore
  const handleDeeplinkCreated = async (result) => {
    dispatch(
      setSelectedDeeplinkPresets([
        ...selectedDeeplinkPresets,
        {
          // @ts-ignore
          dataId: result.uuid,
          uuid: result.uuid,
          name: result.shortLink || '',
          shortLink: result.shortLink || '',
          link: result.link || '',
          ...selectedDeeplinkPreset,
        },
      ]),
    );

    await new Promise((resolve) => setTimeout(resolve, TIMEOUT_DURATION));

    setSelectedDeeplink(result.uuid);
    // @ts-ignore
    await dispatch(adminLoanPresetById(result.uuid));
    dispatch(
      addAlert({
        text: DeeplinkAlert.GENERATE,
        type: AlertType.success,
      }),
    );
    setDeepLinkStatusText(DeeplinkStatus.GENERATED);

    // @ts-ignore
    await dispatch(adminLoanPresetUpdate(data))
      .unwrap()
      .then(() => {
        dispatch(setSelectedDeeplinkPresetLoading(true));
      })
      // @ts-ignore
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        dispatch(setSelectedDeeplinkPresetLoading(false));
      });
    // @ts-ignore
    await dispatch(adminLoanPresetById(selectedDeeplinkPreset?.uuid));

    dispatch(
      addAlert({
        text: DeeplinkAlert.UPDATE,
        type: AlertType.success,
      }),
    );
  };

  const handleDeeplinkUpdated = async () => {
    const { useFiona, skipKYC, type, feeEnabled, whoIsPaying, feeAmountPercentage, affiliate } =
      values;

    const data = {
      uuid: selectedDeeplinkPreset?.uuid,
      displayName: deepLinkName,
      type,
      feeEnabled,
      whoIsPaying,
      feeAmountPercentage,
      flowType: affiliate?.flowType,
      partnerComissionEnabled: affiliate?.partnerComissionEnabled,
      partnerComissionPercentageAmount: affiliate?.partnerComissionPercentageAmount,
      useFiona,
      skipKYC,
    };
    dispatch(setSelectedDeeplinkPresetLoading(true));
    try {
      // @ts-ignore
      await dispatch(adminLoanPresetUpdate(data)).unwrap();

      await new Promise((resolve) => setTimeout(resolve, 3000));

      // @ts-ignore
      await dispatch(adminLoanPresetById(selectedDeeplinkPreset?.uuid));

      dispatch(
        addAlert({
          text: DeeplinkAlert.UPDATE,
          type: AlertType.success,
        }),
      );
      if (!paymentMethods.length) {
        setIsPaymentMethodsEmpty(true);
      }
    } catch (err) {
      console.log(err);
    } finally {
      dispatch(setSelectedDeeplinkPresetLoading(false));
    }
  };

  const handleDeeplinkActivated = async () => {
    await new Promise((resolve) => setTimeout(resolve, TIMEOUT_DURATION));

    // @ts-ignore
    await dispatch(adminLoanPresetById(selectedDeeplink));
    dispatch(
      addAlert({
        text: DeeplinkAlert.ACTIVATE,
        type: AlertType.success,
      }),
    );
    if (!paymentMethods.length) {
      setIsPaymentMethodsEmpty(true);
    }

    setDeepLinkStatusText(DeeplinkStatus.ACTIVATED);

    await new Promise((resolve) => setTimeout(resolve, TIMEOUT_DURATION));

    await handleDeeplinkUpdated();

    setIsEditingDeeplinkName(false);
  };

  const handleChangeDeeplinkStatus = async () => {
    if (!selectedPartner?.uuid) {
      return;
    }

    const deeplinkBody = {
      partnerId: selectedPartner.uuid,
    };

    try {
      dispatch(setSelectedDeeplinkPresetLoading(true));
      if (
        deepLinkStatusText === DeeplinkStatus.NO_GENERATED ||
        selectedDeeplinkPreset?.state === LoanPresetStateEnum.ACTIVE
      ) {
        const result = await createDeeplink(deeplinkBody);
        await handleDeeplinkCreated(result);
      } else if (
        deepLinkStatusText === DeeplinkStatus.GENERATED ||
        selectedDeeplinkPreset?.state === LoanPresetStateEnum.DEACTIVATED
      ) {
        await activateDeeplink();
        await handleDeeplinkActivated();
      }
    } catch (error) {
      console.error('Error handling deeplink status change:', error);
    } finally {
      dispatch(setSelectedDeeplinkPresetLoading(false));
    }
  };

  // eslint-disable-next-line
  const infoBlock = () => {
    if (deepLinkStatusText === DeeplinkStatus.NO_GENERATED) {
      return <div>No deeplink generated yet</div>;
    }
    if (selectedDeeplinkPreset?.state !== LoanPresetStateEnum.ACTIVE) {
      return (
        <div>
          {/* eslint-disable-next-line */}
          Modify the campaign&lsquo;s data below. Be sure to hit{' '}
          {/* eslint-disable-next-line */}
          <span className="active-text">ACTIVATE</span>{' '}when you&lsquo;re done.
        </div>
      );
    }
    if (selectedDeeplinkPreset?.state === LoanPresetStateEnum.ACTIVE) {
      return (
        <div>
          Campaign active! If you need to make changes, be sure to hit the
          {/* eslint-disable-next-line */}
          <span className="active-text">{' '}UPDATE CAMPAIGN</span>{' '}
          {/* eslint-disable-next-line */}
          icon - to the right of{' '}<span className="active-text">GENERATE NEW CAMPAIGN</span>.
        </div>
      );
    }
  };

  const buttonNames = {
    [DeeplinkStatus.NO_GENERATED]: 'Generate new campaign',
    [DeeplinkStatus.GENERATED]: 'Activate campaign',
    [DeeplinkStatus.ACTIVATED]: 'Generate new campaign',
  };

  const deepLinkButtonNames = () => buttonNames[deepLinkStatusText];

  const handleChangeDeeplinkName = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setIsEditingDeeplinkName(true);
    setDeepLinkName(e.target.value);
  };

  const isMainButtonDisabledState = () => {
    if (deepLinkButtonNames() === 'Activate campaign') {
      if (selectedDeeplinkPresetLoading) {
        return true;
      }

      return !isValid;
    }
    return selectedDeeplinkPresetLoading;
  };

  const isButtonDisabled = (status: DeeplinkStatus, loading: boolean, isValid: boolean) => {
    if (status === DeeplinkStatus.GENERATED) {
      return loading || !isValid;
    }
    return loading;
  };

  return (
    <form className="partners-deeplinks" onSubmit={handleSubmit}>
      <div className="partners-deeplinks__main">
        <div className="partners-deeplinks__select-block">
          <div className="partners-deeplinks__label">Select the partner&apos;s deeplink:</div>
          <Select
            classes="select--no-margin"
            id="deeplink"
            // @ts-ignore
            data={selectedDeeplinkPresets}
            value={selectedDeeplink}
            onChange={(arg) => handleChangeSelectDeeplink(arg)}
            disabled={selectedDeeplinkPresetLoading}
          />
        </div>
        {selectedDeeplink !== 'No deeplink' ? (
          <div className="partners-deeplinks__select-block">
            <div className="partners-deeplinks__label">
              Name your campaign
              <span className="partners-deeplinks__secondary-text">(for internal use only)</span>
            </div>
            <Input
              type="text"
              id="displayName"
              defaultValue={values.displayName}
              value={deepLinkName}
              placeholder="ex. “Decline Flow A”, “Main Newsletter”, “IG-Story”"
              disabled={selectedDeeplinkPresetLoading}
              onChange={handleChangeDeeplinkName}
            />
          </div>
        ) : (
          ''
        )}
        <div className="partners-deeplinks__generate-block">
          <div className="partners-deeplinks__generate-block-info">
            <Icon classes="icon-info-glyph" name="glyph" />
            {infoBlock()}
          </div>
          <div
            className={classNames('', {
              'partners-deeplinks__generate-buttons':
                selectedDeeplinkPreset?.state === LoanPresetStateEnum.ACTIVE,
            })}
          >
            <Button
              className={classNames(
                'button--primary-blue button--lg partners-deeplinks__generate-button',
                {
                  'partners-deeplinks__generate-button__isDirty':
                    selectedDeeplinkPreset?.state === LoanPresetStateEnum.ACTIVE,
                },
              )}
              onClick={handleChangeDeeplinkStatus}
              type="button"
              disabled={isMainButtonDisabledState()}
            >
              {deepLinkButtonNames()}
            </Button>
            {selectedDeeplinkPreset?.state === LoanPresetStateEnum.ACTIVE ? (
              <>
                <div className="buttons-divider" />
                <Tooltip text="Update campaign" position="bottom center">
                  <div>
                    <Button
                      className="button--primary-blue button--lg partners-deeplinks__generate-button refresh-button"
                      type="submit"
                      disabled={isButtonDisabled(
                        deepLinkStatusText,
                        selectedDeeplinkPresetLoading,
                        isValid,
                      )}
                    >
                      {/* @ts-ignore */}
                      <Icon classes="icon-info-glyph" name="refresh" />
                    </Button>
                  </div>
                </Tooltip>
              </>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
      <PartnersDeeplinksDetails
        paymentMethods={paymentMethods}
        isPaymentMethodsEmpty={isPaymentMethodsEmpty}
        setIsPaymentMethodsEmpty={setIsPaymentMethodsEmpty}
        selectedDeeplinkPreset={selectedDeeplinkPreset}
        values={values}
        setFieldValue={setFieldValue}
        setValues={setValues}
        handleChange={handleChange}
        loanType={loanType}
        setLoanType={setLoanType}
      />
    </form>
  );
};
