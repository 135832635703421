import { Icon } from '../../../../shared';
import { Amplitude, AmplitudeEvents } from '../../../../shared/services/amplitude';
import { FlowTypes } from '../../../auth/store';

import { SelectionOption } from './data';

const FilteredSelectionOptions = ({
  filteredSelectionOptions,
  value,
  setValue,
}: {
  filteredSelectionOptions: SelectionOption[];
  value: keyof typeof FlowTypes;
  setValue: (value: keyof typeof FlowTypes) => void;
}) => {
  const handleCardClick = (flowType: keyof typeof FlowTypes) => {
    if (value === flowType) {
      let product = '';
      if (value === FlowTypes.AFFILIATE_PARTNER) {
        product = 'DF';
      } else if (value === FlowTypes.BILL_PAY) {
        product = 'ZP';
      } else if (value === FlowTypes.LOAN_CREATOR) {
        product = 'LC';
      } else if (value === FlowTypes.BNPL) {
        product = 'BNPL';
      }

      Amplitude.logEvent(AmplitudeEvents.ppProductDeselected, {
        product,
      });

      setValue(FlowTypes.UNKNOWN);
    } else {
      setValue(flowType);
    }
  };

  return (
    <div className="selectionFlow__wrapper">
      {filteredSelectionOptions.map((s) => (
        // eslint-disable-next-line
        <div
          key={s.title}
          className={`selectionFlow__card ${
            value === s.flowType ? 'selectionFlow__card--active' : ''
          }`}
          onClick={() => handleCardClick(s.flowType)}
        >
          <div className="selectionFlow__card__header">
            <div className="selectionFlow__card__header__left">
              <div className="selectionFlow__card__logo">
                <img src={s.logo} alt={s.title} />
              </div>
              <div className="selectionFlow__card__info">
                <div className="selectionFlow__card__title">{s.title}</div>
                <div className="selectionFlow__card__subtitle">{s.subtitle}</div>
              </div>
            </div>
            <div className="selectionFlow__card__header__right">
              <div className="selectionFlow__card__selectBtn">
                <Icon name={value === s.flowType ? 'deselect-blue' : 'success-blue'} />
                {value === s.flowType ? 'Deselect' : 'Select'}
              </div>
            </div>
          </div>
          <div className="selectionFlow__card__info">{s.info}</div>
          <div className="selectionFlow__card__imageList">
            <div className="selectionFlow__card__imageItem">
              <img src={s.image1} alt={s.title} />
            </div>
            <div className="selectionFlow__card__imageItem">
              <img src={s.image2} alt={s.title} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FilteredSelectionOptions;
