import numeral from 'numeral';
import React from 'react';

interface AnalyticsSectionProps {
  title?: string;
  value: number;
  percent: number;
  hasDollar?: boolean;
}
export default function AnalyticsSection({
  title,
  value,
  percent,
  hasDollar,
}: AnalyticsSectionProps) {
  const triangleDirection = percent < 0 ? 'triangle-down' : 'triangle-up';

  return (
    <div className="loans-overview__analytics-section">
      <h3 className="loans-overview__metric-section__title">{title}</h3>
      <div className="loans-overview__metric-section__analytic-value">
        {hasDollar ? '$' : ''}
        {hasDollar ? numeral(value).format('0,0.00') : numeral(value).format('0,000')}
      </div>
      <div className="loans-overview__metric-section__percent-block">
        <div className="loans-overview__metric-section__percent-container">
          <div className={`triangle ${triangleDirection}`} />
          {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
          <div className="loans-overview__metric-section__percent-value">{percent}%</div>
        </div>

        <div className="loans-overview__metric-section__percent-text">vs. prior period</div>
      </div>
    </div>
  );
}
