import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  ScriptableContext,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
);

const DATA_COUNT = 5;
const labels: string[] = [];
for (let i = 0; i < DATA_COUNT; i += 1) {
  labels.push(i.toString());
}

const options = {
  responsive: true,
  events: [],
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  interaction: {
    intersect: false,
  },
  scales: {
    x: {
      ticks: {
        display: false,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
      border: {
        display: false,
      },
    },
    y: {
      ticks: {
        display: false,
        beginAtZero: true,
      },
      grid: {
        drawBorder: false,
        display: false,
      },
      border: {
        display: false,
      },
    },
  },
};

const data = () => ({
  labels,
  datasets: [
    {
      data: [0, 10, 50, 30, 80],
      fill: 'start',
      backgroundColor: (context: ScriptableContext<'line'>) => {
        const { ctx } = context.chart;
        const gradient = ctx.createLinearGradient(0, 0, 0, 60);
        gradient.addColorStop(1, 'rgba(255,255,255)');
        gradient.addColorStop(0, 'rgb(75 158 235)');
        return gradient;
      },
      borderColor: '#004F99',
      cubicInterpolationMode: 'monotone' as const,
      tension: 0.4,
    },
  ],
});
export default function LineChart() {
  return (
    <div className="loans-overview__metric-section-zp__lineChart">
      <Line options={options} data={data()} />
    </div>
  );
}
